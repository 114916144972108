document.addEventListener("DOMContentLoaded", function() {
  let chatForm = document.querySelector('.chat-form')

  if (chatForm) {
    chatForm.addEventListener('submit', handleChatSubmit)
    let messages = document.querySelectorAll('.chat-messages > .chat-message')
    if (!(messages && messages.length)) {
      fetch('/ai_chat/chat').then(res => res.text()).then(html => {
        document.querySelector('.chat-messages').innerHTML = html
        chatForm.closest('.chat-container').style.display = 'block';
        scrollChatToBottom()
      })
    }

    chatForm.addEventListener('keypress', e => {
      if (e.key == 'Enter') {
        sendMsg(chatForm)
        e.preventDefault()
      }
    })
  }

  let chatPopup = document.querySelector('.chat-popup')
  if (chatPopup) {
    chatPopup.addEventListener('click', e => {
      if (e.target.matches('.fa-times *')) {
        chatPopup.style.display = 'none'
      }
    })
  }

  let chatOpener = document.querySelector('.chat-opener')
  let chatContainer = document.querySelector('.chat-container')
  let chatContainerClose = document.querySelector('.chat-container__close')
  chatOpener.addEventListener('click', e => {
    chatContainer.classList.add('active')
  })
  chatContainerClose.addEventListener('click', e => {
    chatContainer.classList.remove('active')
  })
})

function handleChatSubmit(e) {
  let form = e.target
  showSpinner(form)
  let msgsContainer = form.parentNode.querySelector('.chat-messages')
  if (form.dataset.hasOwnProperty('async')) {
    e.preventDefault();
    let params = new URLSearchParams(new FormData(form))
    if (e.submitter && e.submitter.name && e.submitter.value) {
      params.set(e.submitter.name, e.submitter.value)
    }
    fetch(`/ai_chat/chat?${params}`)
      .then(res => res.text())
      .then(html => {
        msgsContainer.innerHTML = html
        scrollChatToBottom()
        hideSpinner(form)
      })
  }
}

function scrollChatToBottom() {
  let container = document.querySelector('.chat-messages')
  container && container.scrollTo(0, container.scrollHeight)
}

function sendMsg(chatForm) {
  let textarea = chatForm.querySelector('textarea')
  let msg = textarea && textarea.value

  if (!msg.trim()) return

  let messagesContainer = document.querySelector('.chat-messages')
  if (!messagesContainer) return

  let msgBubble = document.createElement('div')
  msgBubble.classList.add('chat-message', 'user')
  msgBubble.textContent = msg
  messagesContainer.append(msgBubble)
  scrollChatToBottom()

  chatForm.requestSubmit()
  textarea.value = ''
}

function showSpinner(chatForm) {
  let spinner = document.createElement('div')
  let msgsContainer = document.querySelector('.chat-messages')
  spinner.classList.add('chat-bubble')
  spinner.classList.add('chat-message')
  spinner.classList.add('assistant')
  spinner.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="24" viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/></svg>'
  spinner.role = 'status'
  msgsContainer.append(spinner)
  msgsContainer.scrollTop = msgsContainer.scrollHeight;
}

function hideSpinner(chatForm) {
  chatForm.querySelector('.chat-bubble').remove()
}
