$(document).ready(function() {
  // show/hide filters on mobile
  $("#filtersBtn").on("click", function() {
    $("#show").toggle();
    $("#hide").toggle();
    $("#attractionFilters").slideToggle();
  });

  $("#attractionsCarousel").slick({
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: $("#attractionsPrev"),
    nextArrow: $("#attractionsNext"),
    appendDots: $("#attractionsDots"),
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  listenToItineraryClicks()
  scrollChatToBottom()
});

function listenToItineraryClicks() {
  document.addEventListener('click', function (e) {
    console.log(e.target)
    if (e.target.matches('.js-suggest-stop')) {
      e.target.classList.remove('d-flex')
      e.target.classList.add('d-none')
      let form = e.target.parentNode.querySelector('form')
      form.classList.remove('d-none')

      // TODO replace with server rendered inputs in html
      if (!form.querySelector('input[name="stops"]')) {
        let params = new URLSearchParams(location.search)
        params.getAll('stops').forEach(stop => {
          let input = document.createElement('input')
          input.value = stop
          input.name = 'stops'
          input.type = 'hidden'
          form.append(input)
        })
      }

      let select = form.querySelector('select')
      select.addEventListener('change', function(changeEvent) {
        let option = select.querySelector(`option[value="${select.value}"]`)
        if (!option.value) return
        form.requestSubmit()
      })
    }

    if (e.target.matches('.fa-times *, .fa-times')) {
      let badge = e.target.closest('.badge[data-value]')
      if (badge) {
        let value = badge.dataset['value']
        let input = $(`input[name="new_subcategories"][value="${value}"]`)
        if (input) {
          input.remove()
        }
        badge.remove()
      }
    }

    if (e.target.matches('button[data-add-i]')) {
      showAddStop(e.target.dataset.addI)
    }
  })
}

function showAddStop(index) {
  let stopOptions = document.querySelector(`[data-add-i="${index}"][data-add-categories]`)
  if (!stopOptions) return
  if (stopOptions.style.display == 'none') {
    stopOptions.style.display = 'flex';
  } else {
    stopOptions.style.display = 'none'
  }
}

function scrollChatToBottom() {
  let container = document.querySelector('.chat-messages')
  container && container.scrollTo(0, container.scrollHeight)
}
